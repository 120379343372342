<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DELIVERY_TYPES } from '../store'
import { vue } from '@/main'

export default {
  name: 'CarriageMixin',
  created () {
    if (!this.carriageList || (this.carriageList && this.carriageList.length === 0)) {
      this.getCarriageList()
    }
  },
  computed: {
    ...mapGetters({
      carriageList:
        DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.GETTERS.carriageList
    }),
    carriageListOptions () {
      const options = []
      if (this.carriageList && this.carriageList.length > 0) {
        this.carriageList.forEach((carriage) => {
          options.push({
            value: carriage.code,
            text: this.$t(carriage.name)
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setCarriageList:
        DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.MUTATIONS.setCarriageList
    }),
    ...mapActions({
      getCarriageList:
        DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.ACTIONS.getCarriageList
    }),
    onSearchCarriage (search) {
      vue.$store.commit('componentLoading', false)
      this.getCarriageList({ filter__name__icontains: search }).then(() => {
        vue.$store.commit('componentLoading', true)
      })
    },
    selectCarriage (item) {
      this.$set(this.form, 'carriage', item.value)
      this.$set(this.form, 'carriage_name', item.text)
    },
    clearCarriage () {
      this.$set(this.form, 'carriage', null)
      this.$set(this.form, 'carriage_name', null)
    }
  }
}
</script>
