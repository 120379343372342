<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DELIVERY_TYPES } from './store'
import { ROUTES as DELIVERY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import DeliveryTable from './DeliveryTable'
import DeliveryCommonFilters from './DeliveryCommonFilters'
import DeliveryEdit from './DeliveryEdit.vue'

export default {
  name: 'DeliveryList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': DeliveryTable,
    'common-filters': DeliveryCommonFilters,
    'component-edit': DeliveryEdit,
    'component-detail': DeliveryEdit
  },
  data () {
    return {
      DELIVERY_ROUTES,
      title: this.$t('Deliveries'),
      rolePerm: 'carriage_delivery_list',
      actionEnablePermission: ['carriage_delivery_enable'],
      actionDisablePermission: ['carriage_delivery_disable'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__carriage__name__icontains', placeholder: this.$t('Carriage'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.DELIVERY_ROUTES.DELIVERY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('carriage_delivery_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.MUTATIONS.setSelectedList,
      setSelectAll: DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.ACTIONS
    })
  }
}
</script>
