<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            v-if="!id"
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-12 mb-2"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
           <form-thux-horizontal-autocomplete
            :validator="$v.form.carriage"
            :external-errors="errors['carriage']"
            :label-form="'Carriage' | translate"
            class-form="col-12 mb-2"
            :disabled="!formEditable"
            :label-search="'text'"
            :value="form.carriage ? { value: form.carriage, text: form.carriage_name } : null"
            :options="carriageListOptions"
            :placeholder="$t('Type a carriage')"
            :placeholder-field="form.carriage_name"
            @search="onSearchCarriage"
            @select="selectCarriage"
            @clear="clearCarriage"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-select
            :validator="$v.form.transport_by"
            :external-errors="errors['transport_by']"
            :label-form="'Transport by' | translate"
            class-form="col-12 mb-2"
            :disabled="!formEditable"
            label-search="text"
            :value="form.transport_by ? { value: form.transport_by, text: form.transport_by_name } : null"
            :placeholder="$t('Select an option')"
            :options="transportByListOptions"
            @select="selectTransportBy"
            @clear="clearTransportBy"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as DELIVERY_TYPES } from './store'
import { cloneDeep } from 'lodash'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import CarriageMixin from './mixins/CarriageMixin'
import TransportByMixin from './mixins/TransportByMixin'

export default {
  name: 'deliveryEdit',
  mixins: [ThuxDetailPanelMixin, CarriageMixin, TransportByMixin],
  data () {
    return {
      pageName: 'Delivery',
      rolePerm: 'carriage_delivery_retrieve',
      editRolePerm: 'carriage_delivery_update'
    }
  },
  computed: {
    ...mapGetters({
      ...DELIVERY_TYPES.GENERIC.carriage.delivery.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...DELIVERY_TYPES.GENERIC.carriage.delivery.DETAIL.ACTIONS
    }),
    setForm () {
      const form = cloneDeep(this.detail)
      if (this.detail.transport_by) {
        form.transport_by = this.detail.transport_by
        form.transport_by_name = this.detail.get_transport_by_display
      }
      if (this.detail.carriage) {
        form.carriage = this.detail.carriage.code
        form.carriage_name = this.detail.carriage.name
      }
      this.$set(this, 'form', form)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      formData.id = formData.code
      return formData
    }
  },
  validations: {
    form: {
      code: { required },
      transport_by: { required },
      carriage: { required }
    }
  }
}
</script>
