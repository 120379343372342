<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as DELIVERY_TYPES } from '../store'

export default {
  name: 'TransportByMixin',
  created () {
    if (!this.transportByList || (this.transportByList && this.transportByList.length === 0)) {
      this.getTransportByList()
    }
  },
  computed: {
    ...mapGetters({
      transportByList:
        DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.GETTERS.transportByList
    }),
    transportByListOptions () {
      const options = []
      if (this.transportByList && this.transportByList.length > 0) {
        this.transportByList.forEach((instance) => {
          options.push({
            value: instance[0],
            text: instance[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getTransportByList:
        DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.ACTIONS.getTransportByList
    }),
    selectTransportBy (item) {
      this.$set(this.form, 'transport_by', item.value)
      this.$set(this.form, 'transport_by_name', item.text)
    },
    clearTransportBy () {
      this.$set(this.form, 'transport_by', null)
      this.$set(this.form, 'transport_by_name', null)
    }
  }
}
</script>
